global.jQuery = require('jquery');
const $ = jQuery;

require('animsition');
require('svg4everybody')();
const Swiper = require('swiper');
require('venobox');
require('./_modernizr-custom');

$(function(){

		$('.animsition').animsition({
			inClass: 'fade-in',
			outClass: 'fade-out',
			inDuration: 200,
			outDuration: 200,
			linkElement: 'a:not([target="_blank"]):not([href^="#"])',
			loading: false,
			// loadingParentElement: 'body',
			// loadingClass: 'animsition-loading',
			// loadingInner: '', // e.g '<img src="loading.svg" />'
			timeout: true,
			timeoutCountdown: 1000,
			// onLoadEvent: true,
			// browser: [ 'animation-duration', '-webkit-animation-duration'],
			// overlay : false,
			// overlayClass : 'animsition-overlay-slide',
			// overlayParentElement : 'body',
			// transition: function(url){ window.location.href = url; }
		});

	//Venobox
	$('.wp-block-lazyblock-popup').each(function(i){
		$(this).find('.popup').attr('href', '#popup' + (i+1));
		$(this).find('.block-gallery-popup').attr('id','popup' + (i+1));
	});

	var popupBox = $('.venobox').venobox({
		infinigall: true,
		bgcolor: 'transparent',
	});
	$(document).on('click','.next', function(e){
		popupBox.VBnext();
	});
	$(document).on('click','.prev', function(e){
		popupBox.VBprev();
	});
	$(document).on('click','.close', function(e){
		popupBox.VBclose();
	});


	//Swiper
	if($('#slider').length){
		$('#slider > .wp-block-group__inner-container').addClass('swiper-wrapper');
		$('#slider > .wp-block-group__inner-container > .wp-block-lazyblock-cafemenu').addClass('swiper-slide');
		$('#slider > .wp-block-group__inner-container > .wp-block-column').addClass('swiper-slide');
		$('#slider').append('<div class="swiper-pagination"></div>');

		var swiper = undefined;
		var winW =  window.innerWidth;
		var swiperElement = document.getElementById("slider");
		var swiperWrapper = swiperElement.getElementsByClassName("swiper-wrapper");
		var swiperSlide = swiperElement.getElementsByClassName("swiper-slide");
		var options = {
				loop: true,
				pagination: {
					el: '.swiper-pagination',
					clickable: true,
				},
				navigation: {
					nextEl: '.swiper-button-next',
					prevEl: '.swiper-button-prev',
				},
				centeredSlides : true,
				slidesPerView: 1,
				spaceBetween: 30

		};
		function initSwiper(){
			if(swiperElement){
				var screenWidth = window.innerWidth;
				if(screenWidth < 740 && swiper == undefined){
					swiper = new Swiper('#slider', options);

				}else if(screenWidth > 740 && swiper != undefined){
					swiper.destroy();
					swiper = undefined;
					for( var i=0;i<swiperWrapper.length; i++ ) {
						swiperWrapper[i].removeAttribute('style');
					}
					for( var i=0;i<swiperSlide.length; i++ ) {
						swiperSlide[i].removeAttribute('style');
					}
				}
			}
		}
		initSwiper();
		window.addEventListener('resize',initSwiper);

	}


	//Link
	$('a[href^=http]').not('[href*="'+location.hostname+'"]').attr('target', '_blank').addClass('blank');
	$('.wp-block-file a').attr('target', '_blank');
	$('.wp-block-button .wp-block-button__link').append('<i></i>');

	var headerHeight = $('.header-section').outerHeight();
	var urlHash = location.hash;
	if(urlHash) {
		$('body,html').stop().scrollTop(0);
		setTimeout(function(){
			var target = $(urlHash);
			var position = target.offset().top - headerHeight;
			$('body,html').stop().animate({scrollTop:position}, 500);
		}, 200);
	}
	$('a[href^="#"]').not('.popup').on('click', function(){
		var href= $(this).attr("href");
		var target = $(href);
		var position = target.offset().top - headerHeight - 100;
		$('body,html').stop().animate({scrollTop:position}, 500);
		return false;
	});

	//Header fixed
	var header = $('header');
	var position = $('header').offset().top + 200;
	$(this).on('load scroll resize', function(){
		if($(this).scrollTop() > position && header.hasClass('is-fixed') == false) {
			header.addClass('is-fixed');
		}
		else if($(this).scrollTop() < position && header.hasClass('is-fixed') == true){
			header.removeClass('is-fixed');
		}
	});

	//Nav toggle
	$('#btn-nav').on('click', function(){
		$('body').toggleClass('open');
	});
	$('#global-nav a[href]').on('click', function(event){
		$('#btn-nav').trigger('click');
	});



});

